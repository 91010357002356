import React from 'react'

import Page from '../../containers/DraggerGame/PlayGame'
import Seo from '../../containers/Seo'
import justfont from '../../games/justfont.json'

const Game = (props) => {
  return (
    <>
      <Seo.JustFont />
      <Page data={justfont} {...props} />
    </>
  )
}

export default Game
